/** @jsx jsx */
import { Link, graphql } from "gatsby"
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"
import dates from "../util/date"

const MetaOverline = ({ date }) => (
  <time
    sx={{
      textTransform: "uppercase",
      letterSpacing: ".03125em",
      fontWeight: 300,
      fontSize: 1,
      color: "#888",
    }}
    datetime={date}
  >
    {dates.formatLong(date)}
  </time>
)

const PostListItem = ({ post }) => (
  <div sx={{ mb: 4 }}>
    <MetaOverline date={post.frontmatter.date} />
    <h1 sx={{ mt: 0, mb: 2, fontSize: 4, fontWeight: 500 }}>
      <Link
        to={post.fields.slug}
        sx={{
          color: "text",
          textDecoration: "none",
          borderColor: "transparent",
          // ":hover": {
          //   color: "primary",
          // },
        }}
      >
        {post.frontmatter.title}
      </Link>
    </h1>
    <p sx={{ mt: 0 }}>{post.frontmatter.description || post.excerpt}</p>
  </div>
)

const PostsPage = ({ data: { allMdx } }) => (
  <Layout>
    <SEO title="Blog" />
    <ol sx={{ listStyle: "none", p: 0 }}>
      {allMdx.nodes.map(post => (
        <li>
          <PostListItem post={post} />
        </li>
      ))}
    </ol>
  </Layout>
)

export default PostsPage

export const pageQuery = graphql`
  query AllBlogPostsQuery {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date
          title
          description
        }
        excerpt
        fields {
          slug
        }
      }
    }
  }
`
